import AddCircle from '@mui/icons-material/AddCircle'
import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUp from '@mui/icons-material/ArrowCircleUp'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import { IconButton } from '@mui/material'
import { Headline } from 'drawerPanels/EditElementPanel/DialogElements/Headline'
import { InfoText } from 'drawerPanels/EditElementPanel/DialogElements/InfoText'
import { LinkControl } from 'drawerPanels/EditElementPanel/DialogElements/LinkControl'
import { SelectControl } from 'drawerPanels/EditElementPanel/DialogElements/SelectControl'
import { TextareaControl } from 'drawerPanels/EditElementPanel/DialogElements/TextareaControl'
import { ToggleControl } from 'drawerPanels/EditElementPanel/DialogElements/ToggleControl'
import { FieldDefinition, FieldType } from 'elementDefinitions'
import { useStoreActions, useStoreState } from 'store/hooks'
import { FieldLocator, LinkData, MediaData } from 'utils/types'
import { MediaControl } from './MediaControl'

export type CollectionItemData = {
    [x: string]: unknown
}

export interface CollectionControlProps {
    field: {
        type: FieldType.COLLECTION
        title: string
        itemTitle: string
        valueName: string
        min?: number
        max?: number
        fields: FieldDefinition[]
    }
    collectionItems: CollectionItemData[]
    openPageLinkSelector: (fieldName: FieldLocator) => void
    openFileLinkSelector: (fieldName: FieldLocator) => void
    openMediaSelector: (fieldName: FieldLocator) => void
    deleteMediaSelection: (fieldName: FieldLocator) => void
}

export const CollectionControl = (props: CollectionControlProps): JSX.Element => {
    const {
        field,
        collectionItems,
        openPageLinkSelector,
        openFileLinkSelector,
        openMediaSelector,
        deleteMediaSelection,
    } = props
    const changeElementValue = useStoreActions((actions) => actions.model.changeElementValue)
    const pageList = useStoreState((state) => state.model.pageList)
    const assets = useStoreState((state) => state.model.assets)

    const changeFieldValue = (value: string | LinkData | MediaData, collecionField: string, index: number) => {
        const newValue = [...collectionItems]
        newValue[index][collecionField] = value

        changeElementValue({ newValue, valueName: field.valueName })
    }
    // FIXME: Actually create a proper object here, this causes issues with empty checking etc
    const addCollectionItem = () => {
        const newValue = collectionItems ? [...collectionItems] : []
        newValue.push({})
        changeElementValue({ newValue, valueName: field.valueName })
    }

    const removeCollectionItem = (index: number) => {
        const newValue = collectionItems ? [...collectionItems] : []
        newValue.splice(index, 1)
        changeElementValue({ newValue, valueName: field.valueName })
    }

    const swapCollectionItems = (i1: number, i2: number) => {
        const newValue = collectionItems ? [...collectionItems] : []
        const tmp = newValue[i1]
        newValue[i1] = newValue[i2]
        newValue[i2] = tmp
        changeElementValue({ newValue, valueName: field.valueName })
    }

    const renderField = (subField: FieldDefinition, data: CollectionItemData, index: number): JSX.Element => {
        if (subField.type === FieldType.TEXT) {
            return (
                <TextareaControl
                    label={subField.label}
                    key={subField.label + index}
                    valueName={subField.valueName}
                    value={data[subField.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeFieldValue(newValue, valueName, index)
                    }}
                    lines={subField.lines}
                    multiline={subField.multiLine}
                />
            )
        } else if (subField.type === FieldType.LINK) {
            let val: LinkData = { url: '' }
            if (data[subField.valueName] && data[subField.valueName] !== '') {
                val = data[subField.valueName] as LinkData
            }
            return (
                <LinkControl
                    assets={assets}
                    pageList={pageList}
                    label={subField.label}
                    key={subField.label + index}
                    valueName={subField.valueName}
                    value={val}
                    disableExternal={subField.disableExternal}
                    disableInternal={subField.disableInternal}
                    disableFile={subField.disableFile}
                    openFileLinkSelector={(f: FieldLocator) => {
                        f.subfield = {
                            field: f.fieldName,
                            index: index,
                        }
                        f.fieldName = field.valueName
                        openFileLinkSelector(f)
                    }}
                    openPageLinkSelector={(f: FieldLocator) => {
                        f.subfield = {
                            field: f.fieldName,
                            index: index,
                        }
                        f.fieldName = field.valueName
                        openPageLinkSelector(f)
                    }}
                    onValueChange={(valueName: string, newValue: LinkData) => {
                        changeFieldValue(newValue, valueName, index)
                    }}
                />
            )
        } else if (subField.type === FieldType.MEDIA) {
            let val: MediaData = { __qbd_fileId: '', __qbd_altText: '' }
            if (data[subField.valueName] && data[subField.valueName] !== '') {
                val = data[subField.valueName] as MediaData
            }

            return (
                <MediaControl
                    assets={assets}
                    label={subField.label}
                    key={subField.label + index}
                    valueName={subField.valueName}
                    value={val}
                    openMediaSelector={(f: FieldLocator) => {
                        f.subfield = {
                            field: f.fieldName,
                            index: index,
                        }
                        f.fieldName = field.valueName
                        openMediaSelector(f)
                    }}
                    deleteMediaSelection={(f: FieldLocator) => {
                        f.subfield = {
                            field: f.fieldName,
                            index: index,
                        }
                        f.fieldName = field.valueName
                        deleteMediaSelection(f)
                    }}
                    onValueChange={(valueName: string, newValue: MediaData) => {
                        changeFieldValue(newValue, valueName, index)
                    }}
                />
            )
        } else if (subField.type === FieldType.TOGGLE) {
            return (
                <ToggleControl
                    label={subField.label}
                    key={subField.label + index}
                    valueName={subField.valueName}
                    value={data[subField.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeFieldValue(newValue, valueName, index)
                    }}
                />
            )
        } else if (subField.type === FieldType.SELECT) {
            return (
                <SelectControl
                    label={subField.label}
                    key={subField.label + index}
                    valueName={subField.valueName}
                    options={subField.options}
                    radio={subField.control === 'radio'}
                    value={data[subField.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeFieldValue(newValue, valueName, index)
                    }}
                />
            )
        } else if (subField.type === FieldType.INFO_TEXT) {
            return <InfoText label={subField.label} key={subField.label + index} />
        } else if (subField.type === FieldType.HEADLINE) {
            return <Headline text={subField.text} key={subField.text + index} />
        }

        return <></>
    }

    return (
        <div>
            <h2>{field.title}</h2>

            {collectionItems &&
                collectionItems.map((collectionItem, index) => {
                    return (
                        <div key={field.itemTitle + index}>
                            <h3>
                                {field.itemTitle + '' + index}
                                <IconButton
                                    onClick={() => {
                                        removeCollectionItem(index)
                                    }}
                                >
                                    <RemoveCircle />
                                </IconButton>
                                <IconButton
                                    disabled={index === 0}
                                    onClick={() => {
                                        swapCollectionItems(index, index - 1)
                                    }}
                                >
                                    <ArrowCircleUp />
                                </IconButton>
                                <IconButton
                                    disabled={index === collectionItems.length - 1}
                                    onClick={() => {
                                        swapCollectionItems(index, index + 1)
                                    }}
                                >
                                    <ArrowCircleDown />
                                </IconButton>
                            </h3>
                            {field.fields.map((field) => renderField(field, collectionItem, index))}
                        </div>
                    )
                })}
            <IconButton onClick={addCollectionItem}>
                <AddCircle />
            </IconButton>
        </div>
    )
}
